import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './Navigation.scss'
import MenuHamburgerIcon from '../../../images/icons/menu-Hamburger.inline.svg'
import useHeader from '../useHeader/useHeader'

const Navigation = ({ setIsTitleShow, grayscale, children }) => {
  const { isNavigationOpen, isHeaderFixed, isHeaderShown, onToggleNavigation } = useHeader()

  const getNavigation = () => (
    <nav className={`navigation ${isNavigationOpen ? 'mobile--open' : 'mobile--close'}`}>
      <Link to="/o-nas" className="navigation__link" activeClassName="current">
        O NAS
        <div className="navigation__link-line" />
      </Link>
      <Link to="/uslugi" className="navigation__link" activeClassName="current">
        USŁUGI
        <div className="navigation__link-line" />
      </Link>
      <Link to="/automaty-bhp" className="navigation__link" activeClassName="current">
        AUTOMATY BHP
        <div className="navigation__link-line" />
      </Link>
      <Link to="/partnerzy" className="navigation__link" activeClassName="current">
        PARTNERZY
        <div className="navigation__link-line" />
      </Link>
      <Link to="/kontakt" className="navigation__link" activeClassName="current">
        KONTAKT
        <div className="navigation__link-line" />
      </Link>
    </nav>
  )

  setIsTitleShow(!isNavigationOpen)

  return (
    <div
      className={`
        navigation__wrapper
        ${isHeaderFixed && grayscale && 'navigation__wrapper--grayscale'}
        ${isHeaderFixed && 'navigation__wrapper--fixed'}
        ${(isHeaderShown || (isHeaderFixed && isNavigationOpen)) && 'navigation__wrapper--shown'}
      `}
    >
      <div className="container container--navigation">
        {children}
        <div
          className="mobile-navigation__hamburger"
          onClick={() => onToggleNavigation(!isNavigationOpen)}
          onKeyDown={() => onToggleNavigation(!isNavigationOpen)}
          role="button"
          tabIndex={0}
          aria-label="Navigation Hamburger"
        >
          <MenuHamburgerIcon />
        </div>
        {getNavigation()}
      </div>
    </div>
  )
}

Navigation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  setIsTitleShow: PropTypes.func.isRequired,
  grayscale: PropTypes.bool.isRequired,
}

Navigation.defaultProps = {
  children: {},
}

export default Navigation
