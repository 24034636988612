import React, { useState, useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import './Header.scss'
import BackgroundImage from 'gatsby-background-image'
import { getImage as gpiGetImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Navigation from '../../_shared/Navigation/Navigation'
import Topbar from '../Topbar/Topbar'
import Logo from '../../_shared/Logo/Logo'

const Header = ({ pageTitle, pageTitleDesc, bgImage, headerImage }) => {
  const [isTitleShow, setIsTitleShow] = useState(true)
  const [activeSlide1, setActiveSlide1] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide1(!activeSlide1)
    }, 2500)
    return () => clearInterval(interval)
  })

  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "header1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      image2: file(relativePath: { eq: "header2.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      image3: file(relativePath: { eq: "header3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      WR_logo: file(relativePath: { eq: "WR_logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 500, layout: CONSTRAINED)
        }
      }
    }
  `)

  const getBody = () => (
    <>
      <Topbar />
      <Navigation setIsTitleShow={setIsTitleShow} grayscale={!!bgImage}>
        <Logo variant={bgImage ? 'normal' : 'small'} />
      </Navigation>
      <div className={`header__title ${!isTitleShow ? 'header__title--hidden' : ''}`}>
        <h1 className="header__title-title">{pageTitle}</h1>
        {pageTitleDesc && <h2 className="header__title-description">{pageTitleDesc}</h2>}
      </div>
    </>
  )

  const getSlidesBody = activeSlide1 => (
    <>
      <Topbar />
      <Navigation setIsTitleShow={setIsTitleShow} grayscale={!!bgImage}>
        <Logo variant={bgImage ? 'normal' : 'small'} />
      </Navigation>
      <div className={`header__title ${!isTitleShow ? 'header__title--hidden' : ''}`}>
        <div className={`header__slide ${activeSlide1 ? 'active' : ''}`}>
          <h1 className="header__title-title uppercase">{pageTitle}</h1>
          {pageTitleDesc && <h2 className="header__title-description">{pageTitleDesc}</h2>}
        </div>
        <a
          href="https://www.wr-industry.de/"
          title="https://www.wr-industry.de/"
          className={`header__slide ${activeSlide1 ? '' : 'active'}`}
        >
          <GatsbyImage
            image={data.WR_logo.childImageSharp.gatsbyImageData}
            className="header__slide-img"
            Tag="div"
            alt="W+R"
          />
        </a>
        <div className="header__dots">
          <div
            className={`header__dot ${activeSlide1 ? 'active' : ''}`}
            role="presentation"
            onClick={() => setActiveSlide1(true)}
          />
          <div
            className={`header__dot ${activeSlide1 ? '' : 'active'}`}
            role="presentation"
            onClick={() => setActiveSlide1(false)}
          />
        </div>
      </div>
    </>
  )

  const getImage = () => {
    switch (headerImage) {
      case 1:
        return data.image1
      case 2:
        return data.image2
      case 3:
        return data.image3
      default:
        return data.image1
    }
  }

  return (
    <>
      {bgImage ? (
        <BackgroundImage
          Tag="header"
          className="header header--image"
          {...convertToBgImage(gpiGetImage(bgImage))}
          classId="banner"
        >
          {getSlidesBody(activeSlide1)}
        </BackgroundImage>
      ) : (
        <BackgroundImage
          Tag="header"
          className="header"
          {...convertToBgImage(gpiGetImage(getImage()))}
          classId="banner"
        >
          {getBody()}
        </BackgroundImage>
      )}
    </>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleDesc: PropTypes.string,
  bgImage: PropTypes.shape(),
  headerImage: PropTypes.number,
}

Header.defaultProps = {
  pageTitle: '',
  pageTitleDesc: '',
  bgImage: null,
  headerImage: 1,
}

export default Header
