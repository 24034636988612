import React from 'react'
import './Topbar.scss'
import IconPhone from '../../../images/icons/topbar-phone.inline.svg'
import IconEmail from '../../../images/icons/topbar-email.inline.svg'

const Topbar = () => (
  <div className="topbar">
    <div className="container container--topbar">
      <div className="topbar__element">
        <div className="topbar__icon">
          <IconPhone />
        </div>
        <a className="topbar__text" href="tel: +48 502 969 729">
          +48 502 969 729
        </a>
      </div>
      <div className="topbar__element">
        <div className="topbar__icon">
          <IconEmail />
        </div>
        <a className="topbar__text" href="mailto: info@lipus-safety.pl">
          info@lipus-safety.pl
        </a>
      </div>
    </div>
  </div>
)

export default Topbar
