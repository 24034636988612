import { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'

const FIXED_OFFSET_HEIGHT = 200
const FIXED_OFFSET_HEIGHT_MOBILE = 150
const SHOW_HEADER_OFFSET_HEIGHT = 200
const SHOW_HEADER_OFFSET_HEIGHT_MOBILE = 200
const MOBILE_WIDTH = 991

const useHeader = () => {
  const [isNavigationOpen, toggleNavigation] = useState(false)
  const [isHeaderFixed, setHeaderFixed] = useState(false)
  const [isHeaderShown, showHeader] = useState(false)
  const [isMoblieSize, setMobileSize] = useState(false)
  const [prevScroll, setPrevScroll] = useState(0)

  let showHeaderOffsetHeight = SHOW_HEADER_OFFSET_HEIGHT
  let fixedOffsetHeight = FIXED_OFFSET_HEIGHT

  const onToggleNavigation = useCallback(() => toggleNavigation(!isNavigationOpen), [
    isNavigationOpen,
  ])

  const setFixedHeader = scrollPosition => {
    setHeaderFixed(scrollPosition >= fixedOffsetHeight)
    if (scrollPosition >= fixedOffsetHeight) {
      document.body.classList.add('body--header')
    } else {
      document.body.classList.remove('body--header')
    }
  }

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    setFixedHeader(scrollPosition)

    if (!isMoblieSize) {
      showHeader(scrollPosition > showHeaderOffsetHeight)
    } else {
      showHeader(scrollPosition > showHeaderOffsetHeight && prevScroll > scrollPosition)
    }

    setPrevScroll(scrollPosition <= 0 ? 0 : scrollPosition)
  }

  const debounceScroll = debounce(handleScroll, 18)
  useEffect(() => {
    if (isNavigationOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }

    if (window.innerWidth < MOBILE_WIDTH) {
      showHeaderOffsetHeight = SHOW_HEADER_OFFSET_HEIGHT_MOBILE
      fixedOffsetHeight = FIXED_OFFSET_HEIGHT_MOBILE
    }
    setMobileSize(window.innerWidth < MOBILE_WIDTH)
    window.addEventListener('scroll', debounceScroll)

    return () => {
      window.removeEventListener('scroll', debounceScroll)
    }
  })

  return {
    onToggleNavigation,
    isNavigationOpen,
    isHeaderShown,
    isHeaderFixed,
  }
}

export default useHeader
