import React from 'react'
import './Footer.scss'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Logo from '../../_shared/Logo/Logo'

const Footer = () => {
  const { catalog } = useStaticQuery(graphql`{
  catalog: file(relativePath: {eq: "catalog.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 100, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <footer className="footer">
      <div className="container container--footer">
        <Logo variant="white" />
        <div className="footer__catalog">
          <span>Katalog produktów</span>
          <GatsbyImage
            image={catalog.childImageSharp.gatsbyImageData}
            Tag="div"
            className="footer__catalog-img"
            alt="katalog" />
          <a href="/W+R-INDUSTRY_Catalog_2020_ENG.pdf" title="Katalog produktów" download>
            Pobierz
          </a>
        </div>
        <div className="footer__contact">
          <span>ks. Jana Pojdy 76</span>
          <span>44-213 Książenice</span>
          <span>
            <br />
          </span>
          <a href="tel: +48 502 969 729">+48 502 969 729</a>
          <a href="mailto: info@lipus-safety.pl">info@lipus-safety.pl</a>
        </div>
        <div className="footer__links">
          <Link to="/o-nas" className="footer__link">
            O NAS
          </Link>
          <Link to="/uslugi" className="footer__link">
            USŁUGI
          </Link>
          <Link to="/automaty-bhp" className="footer__link">
            AUTOMATY BHP
          </Link>
          <Link to="/partnerzy" className="footer__link">
            PARTNERZY
          </Link>
          <Link to="/kontakt" className="footer__link">
            KONTAKT
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer
