import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../_shared/ComponentSEO/ComponentSEO'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import './Layout.scss'

const Layout = ({ children, pageTitle, pageTitleDesc, bgImage, headerImage }) => {
  return (
    <>
      <SEO title={pageTitle} />
      <Header
        pageTitle={pageTitle}
        pageTitleDesc={pageTitleDesc}
        bgImage={bgImage}
        headerImage={headerImage}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  pageTitleDesc: PropTypes.string,
  imageFluid: PropTypes.shape(),
  headerImage: PropTypes.number,
}

Layout.defaultProps = {
  pageTitle: '',
  pageTitleDesc: '',
  imageFluid: null,
  headerImage: 1,
}

export default Layout
